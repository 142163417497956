<template>
  <v-card>
    <v-card-title>{{ $router.currentRoute.params.id ? 'Edit' : 'New' }} Job Order</v-card-title>
    <v-card-text>
      <v-fade-transition>
        <v-alert
          v-show="actionAlert"
          border="left"
          color="success"
          dark
          text
          dismissible
        >
          Job Order saved.
        </v-alert>
      </v-fade-transition>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="$store.getters['auth/can'](['create job orders'])"
        color="primary"
        class="mr-2"
        :disabled="saving"
        @click="save"
      >
        Save
      </v-btn>
      <v-btn
        v-if="$router.currentRoute.params.id && !jobOrderForm.has_waste_report && $store.getters['auth/can'](['create waste reports'])"
        color="primary"
        class="mr-2"
        @click="createWasteReport"
      >
        Create Waste Report
      </v-btn>
      <v-btn
        v-if="$router.currentRoute.params.id"
        color="secondary"
        class="mr-2"
        outlined
        :disabled="exporting"
        @click="exportPdf"
      >
        <v-icon
          size="17"
          class="me-1"
        >
          {{ icons.mdiExportVariant }}
        </v-icon>
        Export PDF
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <v-fade-transition>
        <v-alert
          v-model="showAlert"
          border="left"
          :color="alertType"
          dark
          text
          dismissible
        >
          {{ alertType === 'success' ? 'Job order saved.' : 'Please check all the required fields.' }}
        </v-alert>
      </v-fade-transition>
      <v-form class="multi-col-validation">
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-menu
              v-model="issueDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="jobOrderForm.issue_date"
                  label="Issue Date"
                  :prepend-icon="icons.mdiCalendarCheckOutline"
                  readonly
                  v-bind="attrs"
                  outlined
                  dense
                  clearable
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="jobOrderForm.issue_date"
                color="primary"
                @input="issueDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-autocomplete
              v-model="jobOrderForm.vehicle_id"
              aria-required=""
              placeholder="Search by Plate Number.. ."
              :loading="loadingVehicle"
              :error-messages="errors.vehicle_id"
              :items="vehicles"
              :search-input.sync="searchVehicle"
              cache-items
              hide-details="auto"
              label="Vehicle"
              item-text="plate_number"
              item-value="id"
              outlined
              hide-no-data
              dense
              clearable
              @change="setDriver"
              @keyup.enter="fetchVehicles"
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-autocomplete
              v-model="jobOrderForm.job_order_status_id"
              aria-required=""
              placeholder="Search.. ."
              :error-messages="errors.job_order_status_id"
              :items="statuses"
              item-value="id"
              item-text="status"
              cache-items
              hide-details="auto"
              label="Status"
              outlined
              dense
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-menu
              v-model="startDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="jobOrderForm.start_date"
                  label="Start Date"
                  :prepend-icon="icons.mdiCalendarCheckOutline"
                  readonly
                  v-bind="attrs"
                  outlined
                  dense
                  clearable
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="jobOrderForm.start_date"
                color="primary"
                @input="startDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-autocomplete
              v-model="jobOrderForm.driver_id"
              aria-required=""
              placeholder="Search.. ."
              :loading="loadingEmployee"
              :error-messages="errors.driver_id"
              :items="employees"
              :search-input.sync="searchEmployee"
              cache-items
              hide-details="auto"
              label="Driver"
              item-text="full_name"
              item-value="id"
              outlined
              dense
              hide-no-data
              clearable
              @keyup.enter="fetchEmployees(['Driver'])"
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-autocomplete
              v-model="jobOrderForm.maintenance_type"
              aria-required=""
              :items="maintenanceTypes"
              cache-items
              hide-details="auto"
              label="Maintenance Type"
              outlined
              dense
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-menu
              v-model="completionDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="jobOrderForm.completion_date"
                  label="Completion Date"
                  :prepend-icon="icons.mdiCalendarCheckOutline"
                  :error-messages="errors.completion_date"
                  readonly
                  v-bind="attrs"
                  outlined
                  dense
                  clearable
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="jobOrderForm.completion_date"
                color="primary"
                @input="completionDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="jobOrderForm.mileage"
              label="Mileage"
              outlined
              dense
              clearable
              hide-details="auto"
              type="number"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="jobOrderForm.control_number"
              label="Control No"
              outlined
              dense
              placeholder="Auto generated"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <template>
      <v-tabs
        v-model="vehicleTab"
        fixed-tabs
      >
        <v-tab>
          <h4>Services ({{ jobOrderForm.services.length }})</h4>
        </v-tab>
        <v-tab @click="fetchRISItems">
          <h4>RIS</h4>
        </v-tab>
        <v-tab>
          <h4>Direct Parts ({{ jobOrderForm.parts.length }})</h4>
        </v-tab>
        <v-tab>
          <h4>Additional Details</h4>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="vehicleTab">
        <v-tab-item>
          <v-card>
            <v-card-text>
              <div class="add-services-form pb-10 px-8">
                <div
                  v-for="(service, serviceIndex) in jobOrderForm.services"
                  :key="serviceIndex"
                  class="single-service-form"
                  style="margin-top: 1rem"
                >
                  <div class="add-services-header mb-2 d-none d-md-flex">
                    <div class="px-5 flex-grow-1 font-weight-semibold">
                      <v-row>
                        <v-col
                          cols="12"
                          md="8"
                        >
                          <span>Service</span>
                        </v-col>
                        <v-col
                          cols="12"
                          md="4"
                        >
                          <span>Cost</span>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="header-spacer"></div>
                  </div>
                  <v-card
                    outlined
                    class="d-flex"
                  >
                    <!-- Left Form -->
                    <div class="pa-5 flex-grow-1">
                      <v-row>
                        <v-col
                          cols="12"
                          md="9"
                        >
                          <services-autocomplete
                            v-model="service.id"
                            :index="serviceIndex"
                            :has-error="serviceDuplicates.includes(service.id)"
                            :initial="serviceOptions"
                            @setServiceId="setServiceId"
                          ></services-autocomplete>
                        </v-col>
                        <v-col
                          cols="12"
                          md="3"
                        >
                          <v-text-field
                            v-model.number="service.cost"
                            outlined
                            dense
                            min="0"
                            type="number"
                            hide-details="auto"
                            label="Cost"
                            placeholder="$"
                            @input="
                              val => {
                                service.cost = Math.abs(val)
                              }
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="service.mechanics"
                            aria-required=""
                            placeholder="Search.. ."
                            :items="mechanics"
                            hide-details="auto"
                            label="Mechanics"
                            item-text="full_name"
                            item-value="id"
                            outlined
                            dense
                            multiple
                            hide-no-data
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                    </div>
                    <!-- Service Actions -->
                    <div class="d-flex flex-column item-actions rounded-0 pa-1">
                      <v-btn
                        icon
                        small
                        @click="jobOrderForm.services.splice(serviceIndex, 1)"
                      >
                        <v-icon size="20">
                          {{ icons.mdiClose }}
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                    </div>
                  </v-card>
                </div>
                <v-row>
                  <v-col
                    cols="12"
                    md="2"
                    offset="5"
                  >
                    <v-btn
                      color="primary"
                      class="mt-4 col-4"
                      outlined
                      block
                      @click="addNewService"
                    >
                      Add Service
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card class="my-10">
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="jobOrderForm.ris_control_number"
                    label="Control No"
                    outlined
                    placeholder="Enter RIS control number to view items"
                    dense
                    clearable
                    hide-details="auto"
                    @keyup.enter="fetchRISItems"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                  <p class="pt-2">
                    Total:
                    <v-chip
                      small
                      color="success"
                      class="success--text v-chip-light-bg font-weight-semibold text-capitalize"
                    >
                      {{ risTotal.toFixed(2) }}
                    </v-chip>
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text>
              <v-data-table
                :headers="risHeaders()"
                :items="risItems"
                hide-default-footer
              >
                <!-- total -->
                <template #[`item.total`]="{ item }">
                  <span>{{ (item.quantity * item.unit_price).toFixed(2) }}</span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <p class="pt-2">
                    Total:
                    <v-chip
                      small
                      color="success"
                      class="success--text v-chip-light-bg font-weight-semibold text-capitalize"
                    >
                      {{ directPartsTotal.toFixed(2) }}
                    </v-chip>
                  </p>
                </v-col>
              </v-row>
              <div class="add-services-form pb-10 px-8">
                <div
                  v-for="(part, partIndex) in jobOrderForm.parts"
                  :key="partIndex"
                  class="single-service-form"
                  style="margin-top: 1rem"
                >
                  <div class="add-services-header mb-2 d-none d-md-flex">
                    <div class="px-5 flex-grow-1 font-weight-semibold">
                      <v-row>
                        <v-col
                          cols="12"
                          md="8"
                        >
                          <span>Part</span>
                        </v-col>
                        <v-col
                          cols="12"
                          md="2"
                        >
                          <span>Quantity</span>
                        </v-col>
                        <v-col
                          cols="12"
                          md="2"
                        >
                          <span>Unit Price</span>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="header-spacer"></div>
                  </div>
                  <v-card
                    outlined
                    class="d-flex"
                  >
                    <!-- Left Form -->
                    <div class="pa-5 flex-grow-1">
                      <v-row>
                        <v-col
                          cols="12"
                          md="2"
                        >
                          <v-autocomplete
                            v-model="part.unit"
                            :items="units"
                            hide-details="auto"
                            item-text="name"
                            item-value="name"
                            outlined
                            dense
                            clearable
                            hide-no-data
                          ></v-autocomplete>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="part.description"
                            outlined
                            dense
                            clearable
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="2"
                          sm="4"
                        >
                          <v-text-field
                            v-model.number="part.quantity"
                            outlined
                            dense
                            min="0"
                            type="number"
                            hide-details="auto"
                            label="Quantity"
                            placeholder="$"
                            @input="
                              val => {
                                part.quantity = Math.abs(val)
                              }
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          md="2"
                          sm="4"
                        >
                          <v-text-field
                            v-model.number="part.unit_price"
                            outlined
                            dense
                            min="0"
                            type="number"
                            hide-details="auto"
                            label="Unit Price"
                            placeholder="$"
                            @input="
                              val => {
                                part.unit_price = Math.abs(val)
                              }
                            "
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                    <!-- Service Actions -->
                    <div class="d-flex flex-column item-actions rounded-0 pa-1">
                      <v-btn
                        icon
                        small
                        @click="jobOrderForm.parts.splice(partIndex, 1)"
                      >
                        <v-icon size="20">
                          {{ icons.mdiClose }}
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                    </div>
                  </v-card>
                </div>
                <v-row>
                  <v-col
                    cols="12"
                    md="2"
                    offset="5"
                  >
                    <v-btn
                      color="primary"
                      class="mt-4 col-4"
                      outlined
                      block
                      @click="addNewPart"
                    >
                      Add Part
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card class="my-10">
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-autocomplete
                    v-model="jobOrderForm.prepared_by"
                    aria-required=""
                    placeholder="Search.. ."
                    :loading="loadingPreparedBy"
                    :error-messages="errors.prepared_by"
                    :items="preparedByOptions"
                    :search-input.sync="searchPreparedBy"
                    cache-items
                    hide-details="auto"
                    label="Prepared By"
                    item-text="full_name"
                    item-value="id"
                    outlined
                    dense
                    hide-no-data
                    @keyup.enter="fetchPreparedBy(['App Admin', 'Mechanic', 'Engineer'])"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-autocomplete
                    v-model="jobOrderForm.inspected_by"
                    aria-required=""
                    placeholder="Search.. ."
                    :loading="loadingInspectedBy"
                    :error-messages="errors.inspected_by"
                    :items="inspectedByOptions"
                    :search-input.sync="searchInspectedBy"
                    cache-items
                    hide-details="auto"
                    label="Inspected By"
                    item-text="full_name"
                    item-value="id"
                    outlined
                    dense
                    hide-no-data
                    @keyup.enter="fetchInspectedBy(['App Admin', 'Mechanic', 'Engineer'])"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="jobOrderForm.next_appointment_mileage"
                    label="Next Appointment Mileage"
                    outlined
                    dense
                    clearable
                    hide-details="auto"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-menu
                    v-model="nextAppointmentDatePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="jobOrderForm.next_appointment_date"
                        label="Next Appointment Date"
                        :prepend-icon="icons.mdiCalendarCheckOutline"
                        readonly
                        v-bind="attrs"
                        outlined
                        dense
                        clearable
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="jobOrderForm.next_appointment_date"
                      color="primary"
                      @input="nextAppointmentDatePicker = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-textarea
                    v-model="jobOrderForm.description"
                    label="Description"
                    rows="3"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <waste-report-dialog
      ref="dialogWasteReport"
      :ris="risItems"
      :direct="jobOrderForm.parts"
      :form="jobOrderForm"
      @wasteSaved="wasteSaved"
    ></waste-report-dialog>
  </v-card>
</template>

<script>
import { onMounted, ref, watch } from '@vue/composition-api'
import { mdiCalendarCheckOutline, mdiSpeedometer, mdiClose, mdiCogOutline, mdiExportVariant } from '@mdi/js'
import axios from '@axios'
import useEmployees from '../employees/useEmployees'
import useVehicles from '../vehicles/useVehicles'
import useServices from '../services/useServices'
import router from '@/router'
import useJobOrders from './useJobOrders'
import useParts from './useParts'
import useUIResolver from '../useUIResolver'
import ServicesAutocomplete from './ServicesAutocomplete.vue'
import useUnits from '../pre-repairs/useUnits'
import usePreRepairs from '../pre-repairs/usePreRepairs'
import WasteReportDialog from './WasteReportDialog.vue'

export default {
  components: { ServicesAutocomplete, WasteReportDialog },
  setup() {
    const today = new Date()
    const formInit = () => ({
      id: null,
      description: null,
      control_number: null,
      issue_date: today.toISOString().substr(0, 10),
      start_date: today.toISOString().substr(0, 10),
      completion_date: null,
      mileage: null,
      services: [],
      parts: [],
      job_order_status_id: 2,
      driver_id: null,
      prepared_by: null,
      department: null,
      inspected_by: null,
      next_appointment_date: new Date(today.setMonth(today.getMonth() + 6)).toISOString().substr(0, 10),
      next_appointment_mileage: '',
      pre_repair_id: null,
      ris_control_number: null,
      has_waste_report: false,
    })

    const saving = ref(false)
    const exporting = ref(false)
    const dialogWasteReport = ref(null)
    const risTotal = ref(0)
    const directPartsTotal = ref(0)
    const mechanics = ref([])
    const id = ref(null)
    const actionAlert = ref(false)
    const vehicleTab = ref(null)
    const errors = ref([])
    const serviceDuplicates = ref([])
    const partDuplicates = ref([])
    const issueDatePicker = ref(false)
    const startDatePicker = ref(false)
    const nextAppointmentDatePicker = ref(false)
    const completionDatePicker = ref(false)
    const jobOrderForm = ref(formInit())
    const preparedByOptions = ref([])
    const searchPreparedBy = ref(null)
    const loadingPreparedBy = ref(false)
    const inspectedByOptions = ref([])
    const searchInspectedBy = ref(null)
    const loadingInspectedBy = ref(false)
    const loadingEmployee = ref(false)
    const { employees, getEmployees, searchEmployee } = useEmployees()
    const { vehicles, getVehicle, getVehicles, searchVehicle, loading: loadingVehicle } = useVehicles()
    const { services: serviceOptions, getServices, loading: loadingService } = useServices()
    const { statuses, getStatuses, getJobOrder, maintenanceTypes, getRISItems, risItems } = useJobOrders()
    const { parts: partOptions, getParts, searchPart, loading: loadingPart } = useParts()
    const { units, getUnits } = useUnits()

    const { preRepair, getPreRepair } = usePreRepairs()

    const { resolveStockRemainingStatus, risHeaders } = useUIResolver()
    const resetForm = () => {
      jobOrderForm.value = formInit()
    }

    // alerts
    const showAlert = ref(false)
    const alertType = ref('success')

    const fetchVehicles = () => {
      getVehicles({ searchQuery: searchVehicle.value })
    }

    const fetchEmployees = roles => {
      loadingEmployee.value = true
      getEmployees({ roles, searchQuery: searchEmployee.value }).then(() => {
        loadingEmployee.value = false
      })
    }

    const fetchPreparedBy = roles => {
      loadingPreparedBy.value = true
      getEmployees({ roles, searchQuery: searchPreparedBy.value }).then(r => {
        preparedByOptions.value = r.data.data
        loadingPreparedBy.value = false
      })
    }

    const fetchInspectedBy = roles => {
      loadingInspectedBy.value = true
      getEmployees({ roles, searchQuery: searchInspectedBy.value }).then(r => {
        inspectedByOptions.value = r.data.data
        loadingInspectedBy.value = false
      })
    }

    const fetchParts = () => {
      getParts({ search: searchPart.value })
    }

    const fetchServices = event => {
      // get already selected services id then exclude from search
      const servicesIds = jobOrderForm.value.services.map(o => o.id).filter(o => o > 0 && o !== null)
      getServices({ searchQuery: event.target.value, excluded: servicesIds })
    }

    const setServiceId = (item, index) => {
      jobOrderForm.value.services[index].id = item?.id
      jobOrderForm.value.services[index].cost = item?.cost
    }

    const addNewService = () => {
      jobOrderForm.value.services.push({
        id: null,
        cost: null,
      })
    }

    const addNewPart = () => {
      jobOrderForm.value.parts.push({
        unit: 'Piece',
        quantity: 1,
        unit_price: null,
        description: '',
        id: null,
      })
    }

    const setDriver = val => {
      const vehicle = vehicles.value.find(o => o.id === val)
      if (vehicle && vehicle.driver) {
        jobOrderForm.value.driver_id = vehicle.driver.id
        employees.value = vehicle.driver ? [vehicle.driver] : []
      } else {
        jobOrderForm.value.driver_id = null
        employees.value = []
      }
    }

    const refreshAlert = () => {
      showAlert.value = false
      alertType.value = 'success'
    }

    const checkServiceDuplicates = () => {
      const ids = jobOrderForm.value.services
        .map(service => service.id)
        .filter(service => service !== null && service > 0)
      serviceDuplicates.value = ids.filter((item, index) => index !== ids.indexOf(item))

      if (serviceDuplicates.value.length > 0) {
        showAlert.value = true
        alertType.value = 'error'
      }
    }

    const createWasteReport = () => {
      dialogWasteReport.value.isDialogVisible = true
    }

    const save = () => {
      refreshAlert()
      checkServiceDuplicates()

      saving.value = true

      if (serviceDuplicates.value.length === 0) {
        if (jobOrderForm.value.id) {
          axios
            .put(`/job-orders/${jobOrderForm.value.id}`, jobOrderForm.value)
            .then(() => {
              saving.value = false

              // router.push({ name: 'job-orders' })
              actionAlert.value = true
            })
            .catch(error => {
              if (error.response.status === 422) {
                errors.value = error.response.data.errors
              }
              saving.value = false
            })
        } else {
          axios
            .post('/job-orders', jobOrderForm.value)
            .then(() => {
              saving.value = false
              actionAlert.value = true
              router.push({ name: 'job-orders' })
            })
            .catch(error => {
              if (error.response.status === 422) {
                errors.value = error.response.data.errors
              }
              saving.value = false
            })
        }
      }
    }

    const exportPdf = () => {
      exporting.value = true
      window.location.href = `${process.env.VUE_APP_SERVER_URL}/export-job-order/${jobOrderForm.value.id}`
      exporting.value = false
    }

    const fetchRISItems = () => {
      getRISItems(jobOrderForm.value.ris_control_number).then(r => {
        risTotal.value = r.data.reduce((acc, item) => acc + item.quantity * item.unit_price, 0)
      })
    }

    const wasteSaved = () => {
      jobOrderForm.value.has_waste_report = true
    }

    watch(actionAlert, val => {
      if (val) {
        setTimeout(() => {
          actionAlert.value = false
        }, 3000)
      }
    })

    watch(
      jobOrderForm,
      () => {
        directPartsTotal.value = jobOrderForm.value.parts.reduce(
          (acc, item) => acc + item.quantity * item.unit_price,
          0,
        )
      },
      { deep: true },
    )

    onMounted(() => {
      getUnits()

      getStatuses()

      // get mechanics
      getEmployees({ roles: ['Mechanic'], searchQuery: searchPreparedBy.value }).then(r => {
        mechanics.value = r.data.data
      })
    })

    id.value = +router.currentRoute.params.id

    if (id.value) {
      saving.value = true
      getJobOrder(id.value).then(response => {
        const { data } = response

        // get job-order mechanics
        axios.get(`/job-orders/mechanics/${id.value}`).then(r => {
          const services = data.services.map(service => {
            const container = service
            container.id = service.id
            container.cost = service.pivot.cost
            container.mechanics = r.data
              .filter(o => o.job_order_id === id.value && o.service_id === service.id)
              .map(o => o.user_id)

            return container
          })

          // assign temp services/parts/vehicle values
          serviceOptions.value = services
          partOptions.value = data.parts
          vehicles.value = data.vehicle ? [data.vehicle] : []
          employees.value = data.driver ? [data.driver] : []

          preparedByOptions.value = data.prepared_by ? [data.prepared_by] : []
          inspectedByOptions.value = data.inspected_by ? [data.inspected_by] : []

          jobOrderForm.value = data
          jobOrderForm.value.prepared_by = data.prepared_by?.id
          jobOrderForm.value.inspected_by = data.inspected_by?.id
          jobOrderForm.value.services = services
          jobOrderForm.value.parts = data.parts

          saving.value = false

          fetchRISItems()
        })
      })
    }

    if (router.currentRoute.query.vehicle_id) {
      getVehicle(router.currentRoute.query.vehicle_id).then(response => {
        vehicles.value = response.data ? [response.data] : []
        jobOrderForm.value.vehicle_id = response.data.id
      })
    }

    if (router.currentRoute.query.pre_repair_id) {
      jobOrderForm.value.pre_repair_id = +router.currentRoute.query.pre_repair_id
      getPreRepair(router.currentRoute.query.pre_repair_id).then(r => {
        jobOrderForm.value.parts = r.data.pre_repair_parts
      })
    }

    return {
      saving,
      exporting,

      // ui
      resolveStockRemainingStatus,
      risHeaders,

      // alerts
      showAlert,
      alertType,

      // variables
      jobOrderForm,
      vehicleTab,
      errors,
      issueDatePicker,
      startDatePicker,
      nextAppointmentDatePicker,
      completionDatePicker,
      serviceDuplicates,
      partDuplicates,
      actionAlert,
      mechanics,
      risTotal,
      directPartsTotal,

      // refs
      dialogWasteReport,

      // functions
      save,
      createWasteReport,
      addNewService,
      addNewPart,
      setServiceId,
      setDriver,
      exportPdf,
      fetchRISItems,
      wasteSaved,

      // useJobOrder
      statuses,
      getStatuses,
      getJobOrder,
      maintenanceTypes,
      getRISItems,
      risItems,

      // useParts
      partOptions,
      fetchParts,
      searchPart,
      loadingPart,

      // useServices
      serviceOptions,
      fetchServices,
      loadingService,

      // useVehicles
      vehicles,
      fetchVehicles,
      searchVehicle,
      loadingVehicle,

      // useEmployees
      employees,
      getEmployees,
      searchEmployee,
      loadingEmployee,
      fetchEmployees,

      // useUnits
      units,
      getUnits,

      // usePreRepairs
      preRepair,
      getPreRepair,

      fetchPreparedBy,
      preparedByOptions,
      searchPreparedBy,
      loadingPreparedBy,

      fetchInspectedBy,
      inspectedByOptions,
      searchInspectedBy,
      loadingInspectedBy,

      resetForm,

      // icons
      icons: {
        mdiExportVariant,
        mdiCalendarCheckOutline,
        mdiSpeedometer,
        mdiCogOutline,
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';
.app-invoice-editable {
  .header-inputs {
    width: 122px;
  }

  .add-services-form {
    .header-spacer {
      // This is according to item actions width
      width: 39px;
    }
    .item-actions {
      @at-root {
        @include theme--child(add-services-form) using ($material) {
          .item-actions {
            border-left: thin solid map-deep-get($material, 'dividers');
          }
        }
      }
    }
  }
}
</style>
