<template>
  <v-dialog
    v-model="isDialogVisible"
    persistent
    max-width="1100px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Materials Used</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-data-table
                v-model="selected"
                :headers="materialUsedHeaders()"
                :items="materials"
                hide-default-footer
                :single-select="singleSelect"
                show-select
              >
                <!-- total -->
                <template #[`item.total`]="{ item }">
                  <span>{{ (item.quantity * item.unit_price).toFixed(2) }}</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="isDialogVisible = false"
        >
          Close
        </v-btn>
        <v-btn
          color="success"
          :disabled="selected.length === 0"
          @click="create"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import axios from '@axios'
import useUIResolver from '../useUIResolver'
import useJobOrders from './useJobOrders'

const today = new Date()
const formInit = () => ({
  id: null,
  control_number: null,
  report_date: today.toISOString().substr(0, 10),
  waste_materials: [],
  department: null,
  quantity: 1,
  unit_price: 0,
  unit_issue: null,
  description: null,
  sales_or_number: null,
  sales_amount: null,
  job_order_id: null,
})

export default {
  props: {
    ris: {
      default: () => [],
      type: Array,
    },
    direct: {
      default: () => [],
      type: Array,
    },
    form: {
      default: () => {},
      type: Object,
    },
  },
  computed: {
    materials() {
      const items = this.ris.map(o => {
        const container = {}
        container.id = o.id
        container.quantity = o.quantity
        container.unit_price = o.unit_price
        container.description = o.product.name

        return container
      })

      const items2 = this.direct.map(o => {
        const container = {}
        container.id = o.id ? o.id : Math.floor(Math.random() * 1000)
        container.quantity = o.quantity
        container.unit_price = o.unit_price
        container.description = o.description

        return container
      })

      return [...items, ...items2]
    },
  },
  setup(props, { emit }) {
    const isDialogVisible = ref(false)
    const selected = ref([])
    const singleSelect = ref(false)
    const wasteReportForm = ref(formInit())

    const { materialUsedHeaders } = useUIResolver()
    const { risItems } = useJobOrders()

    const create = () => {
      wasteReportForm.value.waste_materials = selected
      wasteReportForm.value.job_order_id = props.form.id
      axios.post('/waste-reports', wasteReportForm.value).then(() => {
        emit('wasteSaved')
      })

      isDialogVisible.value = false
    }

    return {
      isDialogVisible,
      materialUsedHeaders,
      risItems,
      selected,
      singleSelect,
      create,
    }
  },
}
</script>
