<template>
  <v-autocomplete
    :value="value"
    placeholder="Search.. ."
    :loading="loadingService"
    :items="serviceOptions"
    hide-details="auto"
    label="Select Service"
    outlined
    item-text="description"
    item-value="id"
    dense
    clearable
    hide-no-data
    class="mb-3"
    return-object
    :search-input.sync="searchService"
    :error-messages="hasError ? 'Duplicate entry' : ''"
    @keyup.enter="fetchServices"
    @change="updateValue"
  >
    >
  </v-autocomplete>
</template>

<script>
import { onMounted } from '@vue/composition-api'
import useServices from '../services/useServices'

export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    value: null,
    hasError: {
      type: Boolean,
      required: true,
    },
    initial: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { services: serviceOptions, getServices, searchService, loading: loadingService } = useServices()

    const fetchServices = () => {
      getServices({ searchQuery: searchService.value }).then(() => {})
    }

    const updateValue = val => {
      emit('setServiceId', val, props.index)
    }

    onMounted(() => {
      if (props.initial.length > 0 && props.value !== null) {
        serviceOptions.value = props.initial.filter(o => o.id > 0 && o.id !== null)
      }
    })

    return {
      updateValue,
      serviceOptions,
      fetchServices,
      searchService,
      loadingService,
    }
  },
}
</script>
